export const environment = {
  production: true,
  envName: "prod",
  api: "http://cloudintercorpretail.pe",
  apiLogin: "https://login.cloudintercorpretail.pe",
  apiStockRead: "https://centralized-stock-read.co.cloudintercorpretail.pe/api/centralized-stock-read/v1",
  apiStockManagement: "https://centralized-stock-management.co.cloudintercorpretail.pe/api/centralized-stock-management/v1",
  apiStockReserve: "https://centralized-stock-reserve.co.cloudintercorpretail.pe/api/centralized-stock-reserve/v1",
  apiStockJob: "https://centralized-stock-job.co.cloudintercorpretail.pe/api/centralized-stock-job/v1",
  apiEntity: "https://entity.cloudintercorpretail.pe/",
  sessionAuth: "centralized-auth",
  sessionData: "centralized-data" 
};
